const DISPLAY_ALERT = 'SHOW_ALERT';
const HIDE_ALERT = 'HIDE_ALERT';
const DISPLAY_CALENDLY_MODAL = 'DISPLAY_CALENDLY_MODAL';
const HIDE_CALENDLY_MODAL = 'HIDE_CALENDLY_MODAL';
const DISPLAY_CONTACT_MODAL = 'DISPLAY_CONTACT_MODAL';
const HIDE_CONTACT_MODAL = 'HIDE_CONTACT_MODAL';
const UPDATE_CURSO = "UPDATE_CURSO";
const BOOK_CURSO_BEGIN = "BOOK_CURSO_BEGIN";
const BOOK_CURSO_SUCCESS = "BOOK_CURSO_SUCCESS";
const BOOK_CURSO_ERROR = "BOOK_CURSO_ERROR";
const MAIL_SENT_SUCCESS = "MAIL_SENT_SUCCESS";
const MAIL_SENT_ERROR = "MAIL_SENT_ERROR";
const LOADING_COMPLETE = "LOADING_COMPLETE"
const HIDE_WELCOME_PANEL = "HIDE_WELCOME_PANEL"
const UPDATE_EMAIL_FOR_EMPRESAS = "UPDATE_EMAIL_FOR_EMPRESAS"


export { HIDE_WELCOME_PANEL, LOADING_COMPLETE, DISPLAY_ALERT, HIDE_ALERT, DISPLAY_CALENDLY_MODAL, HIDE_CALENDLY_MODAL, DISPLAY_CONTACT_MODAL, HIDE_CONTACT_MODAL, UPDATE_CURSO, BOOK_CURSO_BEGIN, BOOK_CURSO_SUCCESS, BOOK_CURSO_ERROR, MAIL_SENT_SUCCESS, MAIL_SENT_ERROR, UPDATE_EMAIL_FOR_EMPRESAS }