import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
// import { Blog } from './pages';

import { AnimatePresence } from "framer-motion";

const NouvelleTerapia = lazy(() => import("./pages/NouvelleTerapia.js"));
const Contacto = lazy(() => import("./pages/Contacto.js"));
const Cursos = lazy(() => import("./pages/Cursos.js"));
const Terapeuta = lazy(() => import("./pages/Terapeuta.js"));
const Sesiones = lazy(() => import("./pages/Sesiones.js"));
const PoliticaDePrivacidad = lazy(() =>
  import("./pages/PoliticaDePrivacidad.js")
);
// const CondicionesDeVenta = lazy(() => import("./pages/CondicionesdeVenta.js"));
const PoliticaDeCookies = lazy(() => import("./pages/PoliticaDeCookies.js"));
const AvisoLegal = lazy(() => import("./pages/AvisoLegal.js"));
const FisioTerapia = lazy(() => import("./pages/Fisioterapia.js"));
const FisioterapiaParaMayores = lazy(() =>
  import("./pages/FisioterapiaParaMayores.js")
);
const FisioterapiaEmpresas = lazy(() =>
  import("./pages/FisioterapiaParaEmpresas.js")
);
const FisioterapiaPostQuirurgica = lazy(() =>
  import("./pages/FisioterapiaPostQuirurgica.js")
);
const FisioterapiaNeuro = lazy(() => import("./pages/FisioterapiaNeuro.js"));
const FisioterapiaADomicilio = lazy(() =>
  import("./pages/FisioterapiaADomicilio.js")
);
const FisioterapiaRespiratoriaAdulta = lazy(() =>
  import("./pages/FisioterapiaRespiratoriaAdulta.js")
);
const FisioterapiaRespiratoriaPediatrica = lazy(() =>
  import("./pages/FisioterapiaRespiratoriaPediatrica.js")
);
const FisioterapiaParaBailarines = lazy(() =>
  import("./pages/FisioterapiaParaBailarines.js")
);
// const Tienda = lazy(() => import("./pages/Tienda.js"));
// const Cart = lazy(() => import("./pages/Cart.js"));

const Osteopatia = lazy(() => import("./pages/Osteopatia.js"));
const LowPressureFitness = lazy(() => import("./pages/LowPressureFitness.js"));
const Nutricion = lazy(() => import("./pages/Nutricion.js"));
const Terapiaemocional = lazy(() => import("./pages/Terapiaemocional.js"));
const NotFound = lazy(() => import("./pages/NotFound.js"));

// const tagManagerArgs = {
//   gtmId: 'GTM-5WP4FTG'
// }
// TagManager.initialize(tagManagerArgs)
// ReactGA.initialize("G-0FEQKGKHM5")

function App() {
  window.dataLayer.push({
    event: "pageview",
  });

  return (
    <AnimatePresence mode="wait">
      <BrowserRouter>
        <Routes>
          <Route
            index
            path="/"
            element={
              <Suspense>
                <NouvelleTerapia />
              </Suspense>
            }
          ></Route>
          <Route
            path="/contacto"
            element={
              <Suspense>
                <Contacto />
              </Suspense>
            }
          ></Route>
          <Route
            path="/sesiones"
            element={
              <Suspense>
                <Sesiones />
              </Suspense>
            }
          ></Route>
          <Route
            path="/terapeuta"
            element={
              <Suspense>
                <Terapeuta />
              </Suspense>
            }
          ></Route>
          <Route
            path="/cursos"
            element={
              <Suspense>
                <Cursos />
              </Suspense>
            }
          ></Route>
          <Route
            path="/politicadeprivacidad"
            element={
              <Suspense>
                <PoliticaDePrivacidad />
              </Suspense>
            }
          />
          <Route
            path="/politicadecookies"
            element={
              <Suspense>
                <PoliticaDeCookies />
              </Suspense>
            }
          />
          <Route
            path="/avisolegal"
            element={
              <Suspense>
                <AvisoLegal />
              </Suspense>
            }
          />
          {/* <Route
            path="/condiciones-generales-de-venta"
            element={<CondicionesDeVenta />}
          /> */}
          <Route
            path="/fisioterapia"
            element={
              <Suspense>
                <FisioTerapia />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-geriatrica"
            element={
              <Suspense>
                <FisioterapiaParaMayores />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-para-empresas"
            element={
              <Suspense>
                <FisioterapiaEmpresas />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-post-quirurgica"
            element={
              <Suspense>
                <FisioterapiaPostQuirurgica />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-para-enfermedades-neurologicas"
            element={
              <Suspense>
                <FisioterapiaNeuro />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-a-domicilio"
            element={
              <Suspense>
                <FisioterapiaADomicilio />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-repiratoria"
            element={
              <Suspense>
                <FisioterapiaRespiratoriaAdulta />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-respiratoria-pediatrica"
            element={
              <Suspense>
                <FisioterapiaRespiratoriaPediatrica />
              </Suspense>
            }
          />
          <Route
            path="/fisioterapia-para-bailarines"
            element={
              <Suspense>
                <FisioterapiaParaBailarines />
              </Suspense>
            }
          />

          {/* <Route path="/tienda" element={<Tienda />}>
            <Route path="/tienda/carrito" element={<Cart />} />
          </Route> */}

          <Route
            path="/osteopatia"
            element={
              <Suspense>
                <Osteopatia />
              </Suspense>
            }
          />
          <Route
            path="/nutricion"
            element={
              <Suspense>
                <Nutricion />
              </Suspense>
            }
          />
          <Route
            path="/terapiaemocional"
            element={
              <Suspense>
                <Terapiaemocional />
              </Suspense>
            }
          />
          <Route
            path="/lowpressurefitness"
            element={
              <Suspense>
                <LowPressureFitness />
              </Suspense>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;
